import * as React from "react";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  AppBar,
  Container,
  Menu,
  MenuItem,
  Box,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import Head from "./head";
import MenuIcon from "@mui/icons-material/Menu";
import { blueDeep } from "../utils/colors";
import { CTA } from "../components/cta";

const pages = ["", "Pricing", "Blog"];
const links = [
  {
    title: "Objectifs",
    link: "#objectifs",
  },
  {
    title: "Qui est concerné ?",
    link: "#qui",
  },
  {
    title: "Les écheances",
    link: "#Echeances",
  },
  {
    title: "Notre solution",
    link: "#agrid",
  },
  {
    title: "FAQ",
    link: "#faq",
  },
];

function Header(props) {
  const { sections, title } = props;

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <React.Fragment>
      <Head />
      <AppBar
        position="static"
        style={{ backgroundColor: blueDeep }}
        position="fixed"
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {links.map((link) => (
                  <MenuItem
                    key={link.title}
                    onClick={() => {
                      document.querySelector(link.link).scrollIntoView({
                        behavior: "smooth",
                      });
                      handleCloseNavMenu();
                    }}
                  >
                    <Typography textAlign="center">{link.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {links.map((link) => (
                <Button
                  key={link?.title}
                  onClick={() => {
                    document.querySelector(link.link).scrollIntoView({
                      behavior: "smooth",
                    });
                    handleCloseNavMenu();
                  }}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {link?.title}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {links.map((link) => (
                  <MenuItem
                    key={link.title}
                    onClick={() => {
                      document.querySelector(link.link).scrollIntoView({
                        behavior: "smooth",
                      });
                      handleCloseNavMenu();
                    }}
                  >
                    <Typography textAlign="center">{link.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <CTA title="Contactez-nous !" link="https://a-grid.com/contact" />
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;
